import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { GameContext } from '../contexts/GameContext';
import GameBackground from '../components/GameBackground';
import GameHeader from '../components/GameHeader';
import Instructions from '../components/Instructions';
import Tips, { Message } from '../components/Tips';
import Funfact, { FunfactProps } from '../pages/games/Funfact';
import Encouragement from '../pages/games/Encouragement';
import Reward from '../pages/games/Reward';
import { NextGameWindow } from '../windows/NextGame';
import { FeedbackWindow } from '../windows/Feedback';
import { CongratsWindow } from '../windows/Congrats';
import { WindowContextProps, useWindow } from '../contexts/WindowContext';
import { saveGamesData } from '../services/cloudFirestore';
import { UserContext } from './UserProvider';
import { UserDataProp } from '../models/userDataProp';
import { SeniorStatistics } from '../models/SeniorStatistics';
import { useParams } from 'react-router';
import { tourbillon, pingpong, memory, sudok, puzzle, reordon, melimots, quizz } from '../models/Games';
import { StatisticsDetails } from '../models/StatisticsDetails';
import { Timestamp } from 'firebase/firestore';

export interface GameRef {
    tips: () => void;
    reset: () => void;
};

export interface GameProps {
    children: ReactNode;
    onTipsClick: () => void;
    colors: {
        primary: string;
        secondary: string;
        border: string;
    },
    endWindows: {
        customImage?: WindowContextProps['customImage'];
        Component: typeof FeedbackWindow | typeof CongratsWindow | typeof NextGameWindow[];
    }[];
    onReset: () => void;
    background?: string;
    tipsEnabled?: boolean;
    instructionEnabled?: boolean;
    instructionMessage?: string;
    congratulation?: {
        title?: string;
        msg?: string;
        video?: string;
    };
    encouragement?: {
        msg: string;
        nbRealisation: number;
        image: string;
        firstName: string;
        lastName: string;
    };
}

export const GameProvider: FC<GameProps> = ({
    children: Game,
    onTipsClick,
    colors,
    endWindows,
    onReset,
    background,
    tipsEnabled,
    instructionEnabled,
    instructionMessage,
    encouragement,
    congratulation,
}) => {
    let timer: NodeJS.Timeout | null = null;
    let windowIndex = 0;
    const { openWindow, closeWindow } = useWindow();
    const [time, setTime] = useState(0);
    const userDataProp: UserDataProp = useContext(UserContext);
    const user = userDataProp.user;
    const { id: gameId, lvlId } = useParams<{ id: string, lvlId: string }>();
    const gameTitle = window.location.pathname.split('/')[1];
    const game = {
        tourbillon,
        pingpong,
        memory,
        sudok,
        puzzle,
        reordon,
        melimots,
        quizz
    }[gameTitle] || null;
    const [showInstruction, setShowInstruction] = useState(instructionEnabled);
    const [showTips, setShowTips] = useState(tipsEnabled);
    const [points, setPoints] = useState<number | undefined>();
    const [message, setMessage] = useState<Message | undefined>();
    const [funfact, setFunfact] = useState<FunfactProps | undefined>();
    const [displayEncouragement, setDisplayEncouragement] = useState(
        encouragement?.firstName
        && encouragement.lastName
        && encouragement.nbRealisation >= 0
        && encouragement.nbRealisation % 5 === 0
        && encouragement.msg
    );
    const [displayCongratulation, setDisplayCongratulation] = useState(false);
    const [blurred, setBlurred] = useState(false);

    const onStartUse = () => {
        setBlurred((prevBlurred) => {
            if (prevBlurred) {
                if (message && message.color === 'info' && message.text === instructionMessage) {
                    setMessage(undefined);
                }
                return false;
            }
            return prevBlurred;
        });
    };

    useEffect(() => {
        const isInstruction = message && message.color === 'info' && message.text === instructionMessage;
        if (isInstruction) {
            setBlurred(true);
        }
        const timer = setTimeout(() => {
            setMessage(undefined);
            if (isInstruction) {
                setBlurred(false);
            }
        }, isInstruction ? 15000 : 5000);

        return () => clearTimeout(timer);
    }, [message]);

    const actions = useMemo(() => ({
        startTimer: () => {
            if (timer === null) {
                setTime(0);
                timer = setInterval(() => {
                    setTime((prevTime) => prevTime + 1);
                }, 1000);
            }
        },
        stopTimer: () => {
            if (timer !== null) {
                clearInterval(timer);
                timer = null;
            }
        },
        resetTimer: () => {
            setTime(0);
        },
        resetPoints: () => {
            setPoints(0);
        },
        addPoints: (value: number) => {
            setPoints((prevPoints) => prevPoints ? prevPoints + value : value);
        },
    }), []);


    const handleTipsClick = () => onTipsClick();

    const handleInstructionsClick = () => {
        setMessage({
            color: 'info',
            text: instructionMessage || 'Posez votre doigt sur la bonne réponse',
        })
        setBlurred(true);
    };

    const handleCloseEncouragement = () => setDisplayEncouragement(false);

    const displayFunfact = (funfact: FunfactProps) => {
        setFunfact(funfact);
    };

    const closeFunfact = () => {
        setFunfact(undefined);
    };

    const showUi = () => {
        setShowInstruction(true);
        setShowTips(true);
    };

    const hideUi = () => {
        setShowInstruction(false);
        setShowTips(false);
    }

    const saveData = (props: any) => {
        setTime((currentTime) => {
            if (user && game) {
                saveGamesData(
                    new SeniorStatistics(
                        gameId,
                        game.type,
                        [new StatisticsDetails({
                            timeTakenToCompleteExercise: currentTime,
                            difficultyLevel: parseInt(lvlId),
                            date: Timestamp.now(),
                            errorCount: props.errorCount,
                            clueCount: props.clueCount,
                            memoryCardFind: props.memoryCardFind,
                            memoryCardReturn: props.memoryCardReturn,
                            pongPlayerScore: props.pongPlayerScore,
                            pongComputerScore: props.pongComputerScore,
                        })]
                    ),
                    user,
                )
            }
            return currentTime;
        });
    };

    const endGame = (props: any) => {
        if (openWindow && closeWindow && windowIndex < endWindows.length) {
            setTime((currentTime) => {
                const Component = endWindows[windowIndex].Component as typeof FeedbackWindow | typeof CongratsWindow | typeof NextGameWindow;
                const customImage = endWindows[windowIndex].customImage;
                openWindow({
                    customImage: customImage,
                    component: () => <Component
                        colors={colors}
                        time={currentTime}
                        congratsFrom={congratulation?.video || congratulation?.msg ? encouragement?.firstName : undefined}
                        displayCongratulation={() => {
                            closeWindow();
                            saveData(props);
                            setDisplayCongratulation(true);
                        }}
                        onClose={() => {
                            closeWindow();
                            windowIndex++;
                            endGame(props);
                        }}
                        onReset={() => {
                            windowIndex = 0;
                            saveData(props);
                            onReset();
                            closeWindow();
                        }}
                    />,
                });
                return currentTime;
            });
        } else if (windowIndex >= endWindows.length) {
            saveData(props);
        }
    };

    return (
        <GameContext.Provider value={{
            startTimer: actions.startTimer,
            stopTimer: actions.stopTimer,
            resetTimer: actions.resetTimer,
            resetPoints: actions.resetPoints,
            addPoints: actions.addPoints,
            writeMessage: setMessage,
            displayFunfact,
            closeFunfact,
            endGame,
            displayInstruction: handleInstructionsClick,
            time,
            points,
            showUi,
            hideUi,
        }}>
            <GameBackground onBlur={!!blurred} background={funfact ? 'white' : background} border={colors.border}>
                <GameHeader quitAction onTouch={() => onStartUse()} color={colors.secondary} time={funfact || displayEncouragement || displayCongratulation ? undefined : time} />
                {displayEncouragement && <Encouragement onClose={handleCloseEncouragement} encouragement={encouragement} />}
                {displayCongratulation && <Reward onReset={() => { onReset(); setDisplayCongratulation(false); }} congratulation={congratulation} />}
                {!displayEncouragement && !displayCongratulation &&
                    <>
                        {funfact && <Funfact {...funfact} color={colors.secondary} />}
                        <div id='game-root' style={{ display: funfact ? 'none' : 'block', 'width': '100%', 'height': '100%' }} onTouchStart={() => onStartUse()} onMouseDown={() => onStartUse()}>{Game}</div>
                        {message === undefined && !funfact && showInstruction && <Instructions onClick={handleInstructionsClick} />}
                        {!funfact && showTips && <Tips message={message} onClick={handleTipsClick} />}
                    </>}
            </GameBackground>
        </GameContext.Provider>
    );
};

// TODO : Récupérer le temps de jeu, le score, lvl, date, nb d'erreur, nb d'indices demandés