/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { CSSProperties, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { CssBaseline, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ImageSlicer } from './reorder1';
import './reorder.css';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import { FormModalAlert } from '../../pages/games/tempComponents/common/FormModalAlert';
import { UserContext } from '../../providers/UserProvider';
import { getExerciseItem, getExercise } from '../../services/cloudFirestore';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { SuccessModal } from '../SuccessModal';
import { GameRef } from '../../providers/GameProvider';
import { useGame } from '../../contexts/GameContext';
import Preview from '../../pages/games/Preview';
import { Loader } from '../Loader';
import { reordon } from '../../models/Games';

export const Reordon = React.forwardRef<GameRef>((_, ref) => {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { endGame, startTimer, stopTimer, resetTimer, showUi, hideUi, writeMessage } = useGame();
    const { id } = useParams<{ id: string }>();
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    const difficultyLevel = parseInt(lvlId);
    const [exercise, setExercise] = useState<Exercise>();
    const [isFirework, setIsFirework] = useState<boolean>(false);
    const [exerciseItem, setExerciseItem] = useState<ExerciseItem>();
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openFormModal, setOpenFormModal] = useState<boolean>(false);
    const [reorderImage, setReorderImage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [nbModal, setNbModal] = useState<number>(0);
    const [isMemorization, setIsMemorization] = useState<boolean>(true);
    const [actualNumber, setActualNumber] = useState<number>(0);
    const [endFrame, setEndFrame] = useState<boolean>(false);
    const [validNumber, setValidNumber] = useState<boolean[]>([]);
    const [isPlayingSound, setIsPlayingSound] = useState<boolean>(false);
    const [isGameLoaded, setIsGameLoaded] = useState<boolean>(false);
    const [itemToShake, setItemToShake] = useState<string>('');
    const numberOfSlices = [2, 3, 4, 5, 5, 5, 5, 5, 5, 6];
    const intruderNumber = [0, 0, 0, 0, 1, 2, 3, 4, 4, 5];
    const [slices, setSlices] = useState<
        { key: number; src: string | undefined; alt: string; activeClue: boolean }[]
    >([]);
    const [reorderError, setReorderError] = useState<number>(0);
    const [reorderClue, setReorderClue] = useState<number>(0);
    const { height, width } = useWindowDimensions();

    useImperativeHandle(ref, () => ({
        tips: () => handleClueClick(),
        reset: () => resetTimer(),
    }));

    useEffect(() => {
        if (isMemorization) {
            resetTimer();
        }
    }, [isMemorization]);

    useEffect(() => {
        if (user?.role === Roles.SENIOR) {
            localStorage.setItem('startTime', new Date().toString());
        }
    }, []);

    useEffect(() => {
        const getExerciseItems = async (): Promise<void> => {
            const globalExercise = await getExercise(id);
            if (globalExercise) {
                setExercise(globalExercise);
            }
            if (
                globalExercise &&
                globalExercise.exerciseImages &&
                globalExercise.exerciseImages[0] &&
                typeof globalExercise.exerciseImages[0] === 'string'
            ) {
                const item = await getExerciseItem(globalExercise.exerciseImages[0]);
                if (item) {
                    setExerciseItem(item);
                    if (item.exerciseImage && typeof item.exerciseImage === 'string') {
                        setReorderImage(item.exerciseImage);
                    }
                }
            }
        };

        getExerciseItems();
    }, [id, user]);

    const handleClueClick = (): void => {
        if (difficultyLevel < 8) {
            if (
                actualNumber <
                numberOfSlices[difficultyLevel - 1] - intruderNumber[difficultyLevel - 1] - 1
            ) {
                const newState = slices.map((slice) => {
                    if (slice.key === actualNumber) {
                        return { ...slice, activeClue: true };
                    }
                    return slice;
                });
                setSlices(newState);
                setReorderClue(reorderClue + 1);
            }
        } else {
            const newSlices = [...slices];
            if (newSlices.length > 1) {
                const index = newSlices.findIndex((item) => Math.trunc(item.key / 100) === 1);

                if (index !== -1) {
                    setItemToShake(slices[index].alt);
                    setTimeout(() => {
                        setItemToShake('');
                        newSlices.splice(index, 1);
                        setReorderClue(reorderClue + 1);
                        setSlices(newSlices);
                    }, 3100);
                }
            }
        }
    };

    const handleCloseFormModal = (): void => {
        setOpenFormModal(false);
    };

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (actualNumber === 1 && difficultyLevel < 8) {
            setIsPlayingSound(true);
            setNbModal(1);
            setTimeout(() => {
                setIsSuccess(true);
                setOpenSuccessModal(true);
                setTimeout(() => {
                    setIsPlayingSound(false);
                    setOpenSuccessModal(false);
                }, 3000);
            }, 1000);
        }

        if (
            actualNumber >=
            numberOfSlices[difficultyLevel - 1] - intruderNumber[difficultyLevel - 1]
        ) {
            setIsPlayingSound(true);
            setNbModal(0);
            setTimeout(() => {
                setIsSuccess(true);
                setIsFirework(true);
                setOpenSuccessModal(true);
                stopTimer();
                setTimeout(() => {
                    setIsPlayingSound(false);
                    setOpenSuccessModal(false);
                    setIsFirework(false);
                    setEndFrame(true);
                }, 3000);
                setTimeout(() => {
                    hideUi();
                    endGame({
                        errorCount: reorderError,
                        clueCount: reorderClue,
                    });
                }, 3000);
            }, 1000);
        }
    }, [actualNumber]);

    if (!reorderImage) {
        return <Loader />;
    }

    const onSkipPreview = () => {
        setIsMemorization(false);
        showUi();
        startTimer();
        if (reordon.instructions) {
            writeMessage({
                text: reordon.instructions.find((instruction) => instruction.maxLvl >= lvl)?.text || '',
                color: 'info',
            });
        }
    };

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            height="100%"
            sx={{
                boxSizing: 'border-box'
            }}>
            {endFrame === true && (
                <div>
                    <div
                        // className="endAnimation"
                        style={{
                            width: '0%',
                            height: '100%',
                            position: 'absolute',
                            backgroundColor: 'red',
                            background: 'linear-gradient(to right, #002E5D 30%, #003b78 70%)',
                            backgroundSize: '50px 100%',
                            zIndex: '2500'
                        }}
                    />
                    <div
                        // className="endAnimation"
                        style={{
                            right: '0%',
                            width: '0%',
                            height: '100%',
                            position: 'absolute',
                            backgroundColor: 'red',
                            background: 'linear-gradient(to right, #002E5D 30%, #003b78 70%)',
                            backgroundSize: '50px 100%',
                            zIndex: '2500'
                        }}
                    />
                </div>
            )}
            <CssBaseline />
            <SuccessModal
                isModelOpen={openSuccessModal}
                setModelOpen={setOpenSuccessModal}
                isSuccess={isSuccess}
                isFirework={isFirework}
                nbModal={nbModal}
                isReorder
                isPlaySound={isPlayingSound}
            />
            {openFormModal && exerciseItem && (
                <FormModalAlert
                    exerciseReceived={exerciseItem}
                    closeModal={handleCloseFormModal}
                    exercise={exercise}
                    difficultyLevel={difficultyLevel}
                    displayInfo
                />
            )}
            {!isMemorization && reorderImage && (
                <Box
                    margin="auto"
                    sx={{
                        width: '100%',
                        height: '100%',
                        msOverflowStyle: 'none',
                    }}>
                    <ImageSlicer
                        imageSrc={reorderImage}
                        numberOfSlices={numberOfSlices[difficultyLevel - 1]}
                        actualNumber={actualNumber}
                        setActualNumber={setActualNumber}
                        validNumber={validNumber}
                        setValidNumber={setValidNumber}
                        difficultyLevel={difficultyLevel}
                        intruderNumber={intruderNumber[difficultyLevel - 1]}
                        setIsSuccess={setIsSuccess}
                        setNbModal={setNbModal}
                        setOpenSuccessModal={setOpenSuccessModal}
                        exercise={exercise}
                        reorderError={reorderError}
                        setReorderError={setReorderError}
                        slices={slices}
                        setSlices={setSlices}
                        itemToShake={itemToShake}
                        setItemToShake={setItemToShake}
                        isGameLoaded={isGameLoaded}
                        setIsGameLoaded={setIsGameLoaded}
                    />
                </Box>
            )}
            {isMemorization && <Preview
                Picture={reorderImage}
                onSkip={() => onSkipPreview()}
            />}
        </Box>
    );
});
