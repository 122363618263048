import Action from '../Action';

import style from './styles.module.css';

interface ButtonProps {
    label: string;
    active?: boolean;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    disabled?: boolean;
    className?: string;
    theme?: 'PRIMARY' | 'SECONDARY';
    size?: 'SMALL' | 'MEDIUM' | 'LARGE';
    action?: string | (() => void);
}

export const Button = ({
    label = '',
    active = false,
    Icon = undefined,
    disabled = false,
    className = '',
    theme = 'PRIMARY',
    size = 'MEDIUM',
    action = undefined,
    ...rest
}: ButtonProps) => {
    const parsedLabel = `${label}`.trim();

    return (
        <Action
            className={`
            ${style.button} 
            ${active ? style.active : ''} 
            ${style[theme.toLowerCase()]} 
            ${style[size.toLowerCase()]} 
            ${disabled ? style.disabled : ''} 
            ${parsedLabel ? style.withText : style.noText} 
            ${className}
            `}
            action={disabled ? undefined : action}
            {...rest}
        >
            <span className={style.text}>{parsedLabel}</span>
            {Icon && <Icon className={style.icon} />}
        </Action>
    );
};

export default Button;
