import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './main.css';
import { env } from '../env';
import { Auth0Provider } from '@auth0/auth0-react';
import { isPlatform } from '@ionic/react';

const container = document.getElementById('root');
const root = createRoot(container!);

export const returnTo = isPlatform('hybrid')
    ? 'fr.stimulin://home'
    : window.location.origin;

if (env.production) {
    Sentry.init({
        dsn: env.production ? env.SENTRY_DSN_PROD : env.SENTRY_DSN_DEV,
        integrations: [
            new BrowserTracing({
                tracePropagationTargets: ['localhost', 'https://appv1.stimulin.fr']
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 1.0
    });
}

// add script to head for matomo
if (env.production) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
        var _paq = (window._paq = window._paq || []);
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function () {
            var u = '//stimulin-matomo.stimulin.fr/';
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.async = true;
            g.src = u + 'matomo.js';
            s.parentNode.insertBefore(g, s);
        })();
    `;
    document.head.appendChild(script);
}

root.render(
   // <React.StrictMode>
        <Auth0Provider
            domain={env.AUTH0_DOMAIN}
            clientId={env.AUTH0_CLIENT_ID}
            useRefreshTokens={true}
            useRefreshTokensFallback={false}
            cacheLocation="localstorage"
            authorizationParams={{
                // token_endpoint_auth_method: 'none',
                // application_type: isPlatform('hybrid') ? "Native" : "SPA",
                redirect_uri: returnTo,
                audience: env.AUTH0_AUDIENCE,
            }}>
            <App />
        </Auth0Provider>
    // </React.StrictMode>
);
