import { Route, BrowserRouter, useLocation } from 'react-router-dom';
import './i18n/config';
import {
    IonApp,
    IonPage,
    IonRouterOutlet,
    getPlatforms,
    isPlatform,
    setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Analytics } from '@vercel/analytics/react';
import { UserContext, UserProvider } from './providers/UserProvider';
import { Header } from './components/Header';
import * as Sentry from '@sentry/react';
import { env } from '../env';
import { SafeArea } from 'capacitor-plugin-safe-area';
import useWindowDimensions from './utilities/useWindowDimensions';
// import { App } from '@capacitor/app';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Router } from './routes';
import bg from './assets/background.svg';
import { getImageKitUrlFrom } from './utilities/utils';
import { ThemeConfig } from './theme';
import { useEffect, useState } from 'react';
import { WindowProvider } from './providers/WindowProvider';

defineCustomElements(window);

setupIonicReact();

// const App: Element = () => {

export default function App(): JSX.Element {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const { width, height, orientation } = useWindowDimensions();

    useEffect(() => {
        (async function(){
            const safeAreaData = await SafeArea.getSafeAreaInsets();
            const {insets} = safeAreaData;
            for (const [key, value] of Object.entries(insets)) {
                document.documentElement.style.setProperty(
                    `--safe-area-inset-${key}`,
                    `${value}px`,
                );
            }
        })()
    }, []);

    return (
        <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError }) => <div>{error.toString()}</div>}>
            <IonApp style={isPlatform('ios') && isPlatform('hybrid') ? {
                marginTop: 'var(--ion-safe-area-top)',
                marginBottom: 'var(--ion-safe-area-bottom)',
                marginLeft: 'var(--ion-safe-area-left)',
                marginRight: 'var(--ion-safe-area-right)',
            } : {}}>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <ThemeConfig>
                            <BrowserRouter>
                                <UserProvider>
                                    <WindowProvider>
                                        <IonPage
                                            style={{
                                                backgroundImage: `url(${getImageKitUrlFrom(bg)})`,
                                                backgroundSize: 'cover'
                                            }}>
                                            <Router />
                                        </IonPage>
                                    </WindowProvider>
                                </UserProvider>
                            </BrowserRouter>
                        </ThemeConfig>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
            {env.production && <Analytics />}
        </Sentry.ErrorBoundary>
    );
}
