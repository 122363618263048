export const env = {
    "VERCEL_API_BUILD_CONTAINERS_ENDPOINT": "https://api-iad1.vercel.com/build-containers",
    "VERCEL_GIT_COMMIT_AUTHOR_NAME": "Stimul'in",
    "TURBO_PLATFORM_ENV": "YOUTUBE_API_KEY_PROD,SENTRY_DSN_DEV,VITE_APP_CONFIG_DEV,SENTRY_DSN_PROD,VITE_APP_CONFIG_PROD,YOUTUBE_API_KEY_DEV,production,API_URL,AUTH0_DOMAIN,AUTH0_CLIENT_ID,AUTH0_AUDIENCE,SENTRY_AUTH_TOKEN,VERCEL_WEB_ANALYTICS_ID",
    "VERCEL_HIVE_CELL_ID": "hvc_f526a54b5d9743b29e31e9294384",
    "NODE": "/node18/bin/node",
    "INIT_CWD": "/vercel/path0",
    "VERCEL_HIVE_ID": "hvi_iad1_maya",
    "VERCEL_GIT_COMMIT_REF": "main",
    "VERCEL_SERVERLESS_SUSPENSE_CACHE": "1",
    "VERCEL_GIT_COMMIT_AUTHOR_LOGIN": "Stimulin",
    "npm_config_global_prefix": "/node18",
    "VERCEL_ENABLE_NPM_DEFAULT": "1",
    "VERCEL_API_BUILD_CONTAINERS_TOKEN": "gdTkLVS2o809Qz7P0klLIxZO",
    "NODE_OPTIONS": "--max_old_space_size=8192",
    "VERCEL_BUILD_OUTPUTS_EDGE_FUNCTION": "1",
    "COLOR": "0",
    "VITE_APP_CONFIG_PROD": "{\"apiKey\":\"AIzaSyBJ_vQMvu5G9KtTyHAuHCsm4BySqXDCb3E\",\"authDomain\":\"stimul-in.firebaseapp.com\",\"projectId\":\"stimul-in\",\"storageBucket\":\"stimul-in.appspot.com\",\"messagingSenderId\":\"119870051968\",\"appId\":\"1:119870051968:web:c7eeb1e25361e95afc7865\",\"measurementId\":\"G-H8H86Y6QCT\"}",
    "npm_config_noproxy": "",
    "npm_config_local_prefix": "/vercel/path0",
    "VERCEL_GIT_REPO_OWNER": "Stimulin",
    "VERCEL_GIT_COMMIT_SHA": "33be6aec6071cbb3a2808c5dc2de0a25a0541d69",
    "VERCEL_EDGE_FUNCTIONS_STRICT_MODE": "1",
    "VERCEL_GIT_PULL_REQUEST_ID": "",
    "VERCEL_EDGE_OTEL_COLLECTOR": "1",
    "YARN_NODE_LINKER": "node-modules",
    "VERCEL_PROJECT_SETTINGS_NODE_VERSION": "18.x",
    "LD_LIBRARY_PATH": "/lib64:/usr/lib64",
    "VERCEL_CLUSTER": "hvi_iad1_maya",
    "USE_RUST_LAYER_LATEST": "1",
    "VITE_APP_CONFIG_DEV": "{\"apiKey\":\"AIzaSyAFMDzlaXMh2Qnl5maQxgCS4n8Dq03EI-Y\",\"authDomain\":\"stimul-in-dev-environment.firebaseapp.com\",\"projectId\":\"stimul-in-dev-environment\",\"storageBucket\":\"stimul-in-dev-environment.appspot.com\",\"messagingSenderId\":\"1017306292621\",\"appId\":\"1:1017306292621:web:c83518e163b881540fddc4\"}",
    "VERCEL_DEPLOYMENT_ID": "dpl_6erCHMcSpko7eE61Z1MAwbknyaF9",
    "npm_config_globalconfig": "/node18/etc/npmrc",
    "VERCEL_HIVE_BANDWIDTH": "150000000",
    "VERCEL_RICHER_DEPLOYMENT_OUTPUTS": "1",
    "VERCEL_NODE_BRIDGE_COMPRESS_MULTI_PAYLOADS": "1",
    "VERCEL_SET_REQUESTED_AT_PRODUCTION_ON_DEPLOYMENT": "1",
    "NEXT_PRIVATE_MULTI_PAYLOAD": "1",
    "VERCEL_ARTIFACTS_TOKEN": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoidGFzay1ydW5uZXIiLCJ1c2VySWQiOiI4bFpyb0ZidE0xbTRmUlIyUE1ocHBCc3MiLCJjYXBhYmlsaXRpZXMiOlsiQVBJX0FSVElGQUNUU19VUExPQUQiLCJBUElfQVJUSUZBQ1RTX0RPV05MT0FEIiwiQVBJX0FSVElGQUNUU19FWElTVFMiLCJBUElfQVJUSUZBQ1RTX1FVRVJZIiwiQVBJX0FSVElGQUNUU19FVkVOVCIsIkFQSV9TUEFDRVNfUlVOX1VQTE9BRCJdLCJkYXRhIjp7InByb2plY3RJZCI6InByal9jNGpDM1FyUUpraVhybnZ1eTFQeklYc3hBOWlUIn0sImlhdCI6MTczMDg5OTczNCwiZXhwIjoxNzMwOTAxNTM0fQ.PqPZQ5lfsc67LGUkkM3VqnQr2ratvlcuCy3ec8A216U",
    "VERCEL_URL": "stimulin-cri8g7ybs-stimulins-projects.vercel.app",
    "AUTH0_AUDIENCE": "https://stimulin.eu.auth0.com/api/v2/",
    "npm_execpath": "/node18/lib/node_modules/npm/bin/npm-cli.js",
    "NEXT_SHARP_PATH": "/usr/local/share/.config/yarn/global/node_modules/sharp",
    "VERCEL_API_ENDPOINT": "https://api-iad1.vercel.com",
    "AWS_EXECUTION_ENV": "vercel-hive",
    "FAKEROOTKEY": "yes",
    "VERCEL_GIT_PROVIDER": "github",
    "VERCEL_EDGE_FUNCTIONS_REGIONAL_INVOCATION": "1",
    "VERCEL_WEB_ANALYTICS_ID": "IVW9ATjk6XZi4hmDGjZPz2jTp",
    "VERCEL_ENABLE_NODE_COMPATIBILITY": "1",
    "VERCEL_FORCE_EDGE_FUNCTIONS_REDEPLOY": "1",
    "VERCEL_GIT_PREVIOUS_SHA": "",
    "SENTRY_DSN_DEV": "https://07d130eb41c228a990e5966e2df2ea13@o4504293305876480.ingest.sentry.io/4505635788554240",
    "VERCEL_CONTAINER_START_TIME": "1730899731647",
    "PATH": "/vercel/path0/node_modules/.bin:/vercel/node_modules/.bin:/node_modules/.bin:/node18/lib/node_modules/npm/node_modules/@npmcli/run-script/lib/node-gyp-bin:/vercel/path0/node_modules/.bin:/yarn1/node_modules/yarn/bin:/vercel/.config/yarn/global/node_modules/.bin:/node18/bin:/ruby32/bin:/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/bun1",
    "VERCEL_ARTIFACTS_OWNER": "team_VuNZx6WFa1ZMHG5fMzTxHgBV",
    "VERCEL_PROJECT_SETTINGS_BUILD_COMMAND": "npm run build",
    "npm_package_json": "/vercel/path0/package.json",
    "npm_config_package_manager_strict": "",
    "_": "/node18/bin/node",
    "VERCEL_DISCOVER_FOLDER_SIZES": "1",
    "USE_OUTPUT_FOR_EDGE_FUNCTIONS": "1",
    "npm_config_userconfig": "/vercel/.npmrc",
    "npm_config_init_module": "/vercel/.npm-init.js",
    "VERCEL_BUILD_PROVIDER": "hive-env",
    "DD_TAGS": "ec2_host:i-08146f869a5e4a9e1\n",
    "NOW_BUILDER": "1",
    "VERCEL_NEXT_BUNDLED_SERVER": "1",
    "npm_command": "run-script",
    "VERCEL_IGNORE_BUILD_STEP_PACKAGE_MANAGER_DETECT": "1",
    "PWD": "/vercel/path0",
    "YOUTUBE_API_KEY_PROD": "AIzaSyA7ZFreE7ix1nyJ-PYyjHMFXW8VH8XY5j4",
    "npm_lifecycle_event": "build",
    "EDITOR": "vi",
    "npm_package_name": "Stimul'in",
    "LANG": "en_US.UTF-8",
    "VERCEL_IMAGE_ID": "sha256:1d7a380d99bc42abba1fdda402399d848b954a6f441c0c7987d95889444eabb1",
    "TZ": ":UTC",
    "VERCEL_BRANCH_URL": "stimulin-git-main-stimulins-projects.vercel.app",
    "VERCEL_USE_STREAMING_PRERENDER": "1",
    "VERCEL_WAKE_UP_DEPLOYMENT": "1",
    "npm_config_npm_version": "10.7.0",
    "SENTRY_AUTH_TOKEN": "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",
    "VERCEL_ROOT_CONTAINER_POLL_TIME": "1730899735210",
    "VERCEL": "1",
    "production": true,
    "VERCEL_PROJECT_PRODUCTION_URL": "appv1.stimulin.fr",
    "npm_config_node_gyp": "/node18/lib/node_modules/npm/node_modules/node-gyp/bin/node-gyp.js",
    "VERCEL_BUILD_IMAGE": "al2",
    "VERCEL_FUNCTION_REGIONS": "iad1",
    "YOUTUBE_API_KEY_DEV": "AIzaSyBR1Den86CcKYvU3lT27lAvVMG8ED5aqsg",
    "VERCEL_SERVERLESS_FUNCTION_FAILOVER": "1",
    "npm_package_version": "1.0.1",
    "VERCEL_STORE_SOURCE_DEPLOYMENT_ON_CACHE": "1",
    "HOME": "/vercel",
    "SHLVL": "2",
    "VERCEL_EDGE_FUNCTIONS_EMBEDDED_SOURCEMAPS": "1",
    "CI": "1",
    "NX_DAEMON": false,
    "AUTH0_DOMAIN": "stimulin.eu.auth0.com",
    "VERCEL_COMPRESS_SERVERLESS_RESPONSE": "1",
    "API_URL": "https://api.stimulin.fr",
    "npm_config_cache": "/vercel/.npm",
    "VERCEL_USE_EDGE_FUNCTIONS_BRIDGE_LATEST": "1",
    "VERCEL_USE_ONLY_STREAMING_LAMBDA": "1",
    "npm_lifecycle_script": "node ./env-script.js && tsc && vite build",
    "VERCEL_RETRY_ON_MISSING_RESPONSE_BATON": "1",
    "VERCEL_GIT_REPO_ID": "632949116",
    "SENTRY_DSN_PROD": "https://141ba0f773f1fc19302d6ecf09d1c2ac@o4504293305876480.ingest.sentry.io/4505635643064320",
    "VERCEL_HIVE_IOPS": "10000",
    "TURBO_REMOTE_ONLY": true,
    "VERCEL_BUILD_MONOREPO_SUPPORT": "1",
    "TURBO_RUN_SUMMARY": true,
    "VERCEL_DEPLOYMENT_ROUTES_BUILD_OUTPUT_V1": "1",
    "npm_config_user_agent": "npm/10.7.0 node/v18.20.4 linux x64 workspaces/false ci/vercel",
    "AUTH0_CLIENT_ID": "0YfDafMORGZJmr6H1tEmeooMsPb2rx2L",
    "VERCEL_DEPLOYMENT_ROUTES_CLEAN": "1",
    "VERCEL_ENV": "production",
    "TURBO_DOWNLOAD_LOCAL_ENABLED": true,
    "VERCEL_HIVE_VERSION": "668a62aa175e99f5e41e2619f778081f4c642502\n",
    "VERCEL_ENABLE_EXTENDED_FALLBACK_PAYLOAD": "1",
    "VERCEL_GIT_COMMIT_MESSAGE": "Vercel deploy",
    "ENABLE_VC_BUILD": "1",
    "VERCEL_PROJECT_ID": "prj_c4jC3QrQJkiXrnvuy1PzIXsxA9iT",
    "VERCEL_EDGE_SUSPENSE_CACHE": "1",
    "DD_TRACE_STARTUP_LOGS": false,
    "VERCEL_ORG_ID": "team_VuNZx6WFa1ZMHG5fMzTxHgBV",
    "__VERCEL_BUILD_RUNNING": "1",
    "npm_node_execpath": "/node18/bin/node",
    "npm_config_prefix": "/node18",
    "VERCEL_GIT_REPO_SLUG": "StimulinV1",
    "VERCEL_USE_BYTECODE_CACHING": "1",
    "VERCEL_PROJECT_SETTINGS_OUTPUT_DIRECTORY": "dist"
};